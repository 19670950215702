<script>
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'filter-dashboard',

  data (){
    return {
      loadingFarms: false,
      loadingModules: false,
      loadingPaddocks: false,
      form: {},
      farms: [],
      modulesRaw: [],
      modules: [],
      paddocksRaw: [],
      paddocks: [],
    }
  },

  computed: {
    ...mapState('dashboard', ['filter']),
    ...mapGetters('auth', ['isManager']),

  },

  watch: {
    'form.module_id'(val) {
      const module = this.modulesRaw.find(item => item.id === val)
      if (!module) return
      this.paddocks = module.paddocks.map(item => ({ text: item.code, value: item.id }))
    },

    filter() {
      if (this.filter.paddockIds !== this.form.paddockIds) {
        this.form.paddockIds = this.filter.paddockIds
        this.$forceUpdate();
      }
    }
  },

  async created() {
    this.changeFilter = debounce(this.storeChangeFilter, 500)
    this.form.startAt = dayjs().subtract(6, 'month').format('YYYY-MM-DD')
    this.form.endAt = dayjs().format('YYYY-MM-DD')
    await this.fetchFarms()

    this.changeFilter()
  },

  methods:{
    storeChangeFilter() {
      this.$store.dispatch('dashboard/changeImageFilter', this.form)
    },

    async fetchFarms() {
      this.loadingFarms = true
      try {
        let result
        if (this.isManager) {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  where: {
                    desactivated: {_is_null: true}
                  },
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  desactivated
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          let desactivatedFarms = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  where: {
                    desactivated: {_eq: true}
                  },
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  desactivated
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          if (desactivatedFarms && desactivatedFarms.data && desactivatedFarms.data.farms) {
            result.data.farms = [...result.data.farms, ...desactivatedFarms.data.farms]
          }
        } else {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
        }
        this.loadingFarms = false
        this.farms = result.data.farms.map(item => ({value: item.id, text: item.name, ...item}));
        let storage = localStorage.getItem('lastFarm')
        if (storage) {
          this.form.farmId = storage
        } else if (this.farms.length > 0) {
          this.form.farmId = this.farms[0].id
        }
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
    },

    farmFilter (item, queryText) {
      const textOne = item?.name?.toLowerCase()
      const textTwo = item?.customer?.name?.toLowerCase()
      const searchText = queryText.toLowerCase()

      // Validação de texto
      if (!textOne || !textTwo) return false

      // Verifica se contém o texto
      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },



    handleChangeFarm() {
      this.form.moduleId = null
      this.form.paddockIds = []
      localStorage.setItem('lastFarm', this.form.farmId)
      this.changeFilter()
    }
  }
}
</script>

<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            v-model="form.farmId"
            :items="farms"
            :loading="loadingFarms"
            :filter="farmFilter"
            label="Fazenda"
            dense
            outlined
            hide-details
            persistent-placeholder
            @input="handleChangeFarm"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-if="!data.item.desactivated" v-html="data.item.name"></v-list-item-title>
                <v-list-item-title v-if="data.item.desactivated" v-html="data.item.name + ' (inativa)'"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.customer.name"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col sm="5">
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="form.startAt"
                label="Data início"
                type="date"
                dense
                outlined
                hide-details
                persistent-placeholder
                @input="changeFilter"
              />
            </v-col>
            <v-col sm="6">
              <v-text-field
                v-model="form.endAt"
                label="Data fim"
                type="date"
                class="text-field-right"
                dense
                outlined
                hide-details
                persistent-placeholder
                @input="changeFilter"
              />
            </v-col>
            <!-- <v-col sm="3">
              <v-range-slider
                max="50"
                min="-50"
              />
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
